<template>
    <footer>
        <div class="c w-l">
            <div class="flex -wrap columns color-secondary -align-stretch">
                <div class="col-12 col-6__s-up col-4__l-up">
                    <div class="footer__logo mb-l">
                        <InlineSvg src="/img/logo.svg" />
                    </div>
                    <address>
                        <a v-if="settings.company_address && settings.company_address.place_id"
                            :href="'https://www.google.com/maps/search/?api=1&query=Kerna%20Luxury%20Stones&query_place_id=' + settings.company_address.place_id"
                            target="_blank" rel="nofollow noreferrer">
                            {{ settings.company_address.parts.street }} {{ settings.company_address.parts.number }}<br />
                            {{ settings.company_address.parts.zip }} {{ settings.company_address.parts.city }}
                        </a><br />
                    </address>
                    <router-link :to="{ name: 'contact' }" class="button -outline bg-neutral mt-l">
                        {{ $t('menu.contact') }}
                    </router-link>
                </div>
                <div class="col-12 col-6__s-up col-4__l-up">
                    <ul class="footer__links">
                        <li v-for="(link, index) in $store.getters['pages']('footer')" :key="'footerlink_' + index">
                            <router-link v-if="link.to" :to="link.to">
                                <span>{{ link.name || link[`name_${$i18n.locale}`] || $t(link.translation) }}</span>
                            </router-link>
                            <div v-else>
                                <span>{{ link.name || link[`name_${$i18n.locale}`] || $t(link.translation) }}</span>
                            </div>
                            <div v-if="link.pages && link.pages.length" class="ml-m">
                                <div v-for="(sublink, subindex) in link.pages" :key="'footersublink_' + subindex">
                                    <router-link v-if="sublink.to" :to="sublink.to">
                                        <span>{{ sublink.name || sublink[`name_${$i18n.locale}`] ||
                                            $t(sublink.translation)
                                        }}</span>
                                    </router-link>
                                    <div v-else>
                                        <span>{{ sublink.name || sublink[`name_${$i18n.locale}`] ||
                                            $t(sublink.translation)
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-6__s-up col-4__l-up">
                    <div class="flex -column">
                        <div>
                            <div class="mb-xl color-accent text-uppercase weight-b" v-if="settings.company_email">
                                {{ $t('contact.email') }}<br />
                                <a :href="'mailto:' + settings.company_email" class="color-secondary weight-r">
                                    {{ settings.company_email }}
                                </a>
                            </div>

                            <div class="color-accent text-uppercase weight-b" v-if="settings.company_phone">
                                {{ $t('contact.phone') }}<br />
                                <a :href="'tel:' + settings.company_phone" class="color-secondary weight-r">
                                    {{ settings.company_phone }}
                                </a>
                            </div>

                            <div class="mb-r mt-xl flex items-center">
                                <a v-if="settings.instagram_url" :href="settings.instagram_url" target="_blank"
                                    rel="nofollow noreferrer" class="color-primary mr-s hovercolor-accent">
                                    <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                            d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                                    </svg>
                                </a>

                                <a v-if="settings.linkedin_url" :href="settings.linkedin_url" target="_blank"
                                    rel="nofollow noreferrer" class="color-primary mr-s hovercolor-accent">
                                    <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                            d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
                                    </svg>
                                </a>

                                <a v-if="settings.facebook_url" :href="settings.facebook_url" target="_blank"
                                    rel="nofollow noreferrer" class="color-primary mr-s hovercolor-accent">
                                    <svg style="width:30px;height:30px" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                            d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                                    </svg>
                                </a>

                                <a v-if="settings.tiktok_url" :href="settings.tiktok_url" target="_blank"
                                    rel="nofollow noreferrer" class="color-primary mr-s hovercolor-accent">
                                    <svg style="width:26px;height:26px" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 2859 3333">
                                        <path fill="currentColor"
                                            d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                                    </svg>
                                </a>

                                <a v-if="settings.whatsapp_nr" :href="`https://wa.me/${settings.whatsapp_nr}`"
                                    target="_blank" rel="nofollow noreferrer" class="color-primary mr-s hovercolor-accent">
                                    <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                            d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
                                    </svg>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="https://projectfive.nl/" class="p5__logo" title="Site by Project Five">
            <InlineSvg src="/img/p5-logo.svg" />
        </a>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    components: {
        InlineSvg: () => import('vue-inline-svg'),
    },
    computed: {
        texts() {
            return this.$store.state.data;
        },

        settings() {
            return this.texts.settings?.[0] || {};
        },
    },
};
</script>

<style>
footer {
    border-top: 0.2rem solid white;
    padding: 10rem 0 8rem;
}

.footer__links {
    margin: 0;
    padding: 0;
}

.footer__links a:hover {
    color: var(--color-accent) !important;
}

footer .columns {
    position: relative;
}

footer .columns>div:not(:first-child) {
    position: relative;
}

footer .columns>div:not(:first-child)::before {
    content: '';
    width: 0.2rem;
    background: white;
    position: absolute;
    left: -2rem;
    top: 1rem;
    bottom: 4rem;
}

footer .columns>div:first-child>address,
footer .columns>div:first-child>a {
    margin-left: calc(0.19 * var(--logo-width));
}

.p5__logo {
    width: 3rem;
    position: absolute;
    bottom: var(--size-l);
    right: var(--size-l);
    color: var(--color-secondary);
}

.p5__logo svg {
    fill: currentColor;
}

.p5__logo:hover .p5logo-slash {
    fill: #e74c3c;
}
</style>
